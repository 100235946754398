.container {
    min-height: 100vh;
    width: 100%;
    min-height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-container {
    margin-top: 100px;
    width: 25rem;
    padding: 1rem 2rem;
    background-color:#eeeeee;
    border-radius: 0.5rem;
}

.login-container .login-form {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
}

.login-container .ant-form-item {
   margin-top: 0.5em;
}

.login-container .login-form-button {
    width: 100%;
    height: 40px;
    border-radius: 10px;
 }

.login-container .logo {
    text-align: center;
    margin-bottom: 2em;
    margin-top: 1em;
}

.login-container .login-message {
    text-align: center;
}

.login-container .ant-input {
    height: 30px;
}

.login-container .header-logo {
    height: 95px;
    width: 248px;
    margin-bottom: 30px;
}

.login-container .login-form .ant-btn-primary, 
.login-container .login-form .ant-btn-primary:hover {
    background: var(--login-button-color) !important; 
    border-color: var(--login-button-color) !important;
}

@media only screen and (max-width: 600px) {
    .container {
        background-color:#eeeeee;
    }

    .login-container {
        margin-top: 20vh;
        width: 100%;
        background-color:#eeeeee;
        border-radius: 0.0rem;
    }
}
