@page-header-height: 40px;

.page-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: @page-header-height;
  min-height: @page-header-height;
  align-items: center;
  margin-bottom: 0px;
  margin-right: 5px;
  margin-left: 5px;
}

.page-header-main {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
}

.page-header-extra {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}

.page-header h1 {
  font-size: 22px;
  margin: 0;
  text-transform: capitalize;
}

.page-header .ant-btn {
  align-items: center;
  display: flex;
  font-weight: bold;
  justify-content: center;
  min-width: 40px;
  column-gap: 5px;
}

.page-header-extra .ant-btn {
  border-radius: 5px;
}

.page-header .back-btn svg {
  margin-right: 2px;
}

.page-header .back-btn svg path {
  fill: var(--default-text-color) !important;
}

@media (max-width: 375px) {
  .page-header .back-header h1 {
    font-size: 18px;
  }
}

@primary-color: #3c61b4;