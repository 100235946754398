.graph-page {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
}

.multi-graph-page {
    overflow: auto;
}

.graph-page .page-body {
    display: flex;
    flex-direction: column;
}

.add-graph {
    border-color: blue;
    border-radius: 2px;
    background-color: #cccccc;
    padding: 5px;
}

.add-graph .ant-form-inline .ant-form-item-with-help {
    margin-bottom: 0px;
}

.graph-list>* {
    margin: 10px 0px;
}

.graph-list :first-child.chart-container {
    margin: 0px 0px;
}

.graph-list {
    padding-top: 1px;
    overflow: auto;
}

.chart-container {
    flex: 1;
}

.flex-input-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.flex-input-group>*:not(:first-child) {
    margin-left: 10px;
}

/* SensorDataChart styles */
.chart-container {
    flex-direction: column;
    border: 1px solid #ccc;
    border-radius: 2px;
}

.chart-header-container {
    background-color: #eee;
    height: 40px;
}

.chart-header-form {
    display: flex;
    padding: 5px 0px;
    align-items: center;
    height: 100%;
}

.chart-header-form>* {
    margin: 0px 5px;
}

.chart-header-form>.chart-variables {
    flex: 1;
    display: flex;
}

.chart-component-main {
    background-color: #fff;
    height: 200px;
}

.chart-sm .chart-component-main {
    height: 100px;
}

.chart-md .chart-component-main {
    height: 200px;
}

.chart-lg .chart-component-main {
    height: 400px;
}

.graph-page .ant-page-header-heading {
    padding: 0px 20px;
    margin-bottom: -10px;
}

.graph-row-gap {
    row-gap: 10px;
    position: relative;
}

.lower-band-line,
.upper-band-line {
    opacity: 1 !important;
}

.highcharts-plot-band {
    opacity: 0.1;
}

.chart-sensor {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    font-weight: bold;
}

.chart-sensor>.chart-title {
    margin-left: 5px;
    margin-right: 5px;
    font-weight: normal;
}

.legend-pill {
    display: inline-block;
    padding: 3px 6px;
    color: white;
    font-weight: bold;
    font-size: 13px;
    border-radius: 10px;
}

.graph-date-range-picker .ant-picker.ant-picker-range {
    max-width: 480px;
}

@media screen and (min-width: 960px) {
    .graph-date-range-picker {
        display: flex;
        justify-content: flex-end;
    }
}

.chart-sm .nested-individual-chart,
.chart-md .nested-individual-chart,
.chart-lg .nested-individual-chart,
.nested-individual-chart {
    height: 150px;
}

.nested-individual-chart-main {
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
}

.nested-individual-chart-header {
    background: #eee;
    padding: 6px;
}

.individual-chart-header {
    display: flex;
    align-items: center;
    gap: 10px;

    .sensor-name {
        font-weight: bold;
    }
}

@primary-color: #3c61b4;