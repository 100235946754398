
.irrigation-equation-card-container {
    .deficit-label, .etc-label, .irrigation-label {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
        margin-top: -15px,
    }

    .deficit-label-text, .etc-label-text, .irrigation-label-text {
        font-style: italic;
        font-size: 20px;
        color: var(--irrigation-equation-label-text);
    }

    .deficit-label-text {
        margin-left: -20px, 
    }

    .etc-label-text {
    margin-left: -17px;
    }

    .irrigation-label-text {
        margin-left: -16px;
    }

    .irrigation-label-value {
        font-weight: 600;
        font-size: 32px;
    }

    .irrigation-label-unit {
        font-weight: 600;
        font-size: 16px;
    }

    .irrigation-label-unit-plus, .irrigation-label-unit-equals {
        padding: 4px;
        font-size: 24px;
    }

    .irrigation-label-container-unit {
        margin-left: 5px;
    }

    .irrigation-label-subContainer-unit {
        margin-top: 7px;
    }

    .irrigation-equation-align-item {
        padding: 2px;
    }

    .irrigation-equation-label-container {
        width: 100%;
        margin-bottom: -4px;
    }

    .irrigation-equation-card-style {
        margin-bottom: 5px;
    }

    .irrigation-equation-card-body-style {
        padding: 2px;
    }
}

 /* ----------- iPhone 5, 5S, 5C and 5SE -----------*/
@media only screen
and (max-device-width: 320px)
and (max-device-width: 568px)
and (-webkit-min-device-pixel-ratio: 2)
and (orientation: portrait) {

    .irrigation-equation-card-container {
        .irrigation-label-value {
            font-weight: 600;
            font-size: 22px;
        }

        .deficit-label-text, .etc-label-text, .irrigation-label-text {
            font-style: italic;
            font-size: 18px;
            color: var(--irrigation-equation-label-text);
        }
    }
    
}
@primary-color: #3c61b4;