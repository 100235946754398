.chill-hours-and-portions {
  .collapse-header-title {
    margin: 0;
    padding: 15px 5px;
  }

  .align-center {
    justify-content: center;
  }
}

.summary-detail {
  font-size: 13px;
  height: 20px;
  background-color: #f8f8f8;
  padding: 0px 5px;
}

.layout-row {
  align-items: center;
  align-content: center;
  max-width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
}

.status-data {
  min-height: 30px;
  font-weight: bold;
  line-height: 1;
}

.status-data:nth-child(even) {
  background-color: #dddddd;
}

.flex-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.flex-div span {
  font-size: 12px;
}

.summary-information-header {
  font-size: 24px;
  line-height: 24px;
  height: 30px;
  min-height: 30px;
}

.summary-information-info {
  background-color: #EEEEEE;
  margin: 10px 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.summary-header {
  font-size: 16px;
  font-weight: bold;
  height: 30px;
  background-color: white;
  padding: 0px 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.summary-detail {
  font-size: 13px;
  height: 20px;
  background-color: #f8f8f8;
  padding: 0px 5px;
}

.status-header {
  height: 30px;
  line-height: 1;
  border-bottom: 1px solid #999;
}

.layout-column {
  flex-direction: column;
}

.layout-row {
  flex-direction: row;
}

.layout-row .flex-100 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box;
}

.flex-50 {
  flex: 1 1 50%;
  max-width: 50%;
  max-height: 50%;
  box-sizing: border-box;
}

.mobile-list-item {
  background-color: white;
}

.gdd-form .form-group {
  margin: 5px;
  padding: 5px;
}

.margin-bottom-less {
  margin-bottom: -15px;
}

/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */
/* Portrait */
@media only screen
  and (min-device-width: 320px)
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {
    .chill-hours-and-portions {
      .ant-collapse-content-box {
        font-size: 14px;
      }

      .status-header {
        height: 40px;
        border-bottom: 1px solid #999;
        text-align: center;
      }

      .header-ellipsis {
        margin-top: 0px;
        margin-bottom: 0px;
      }

      .ant-collapse-content>.ant-collapse-content-box {
        padding: 0px;
      }

      .ant-table-cell {
        min-width: 115px;
      }

      .ant-picker {
        padding: 4px 4px 4px;
      }

      .ant-table-tbody > tr > td {
        padding: 16px 7px;
      }
    }
  }

  /* ----------- iPhone 6, 6S, 7 and 8 -----------*/
  @media only screen
  and (min-device-width: 375px)
  and (max-device-width: 667px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait){

    .precipitation-column-style {
      width: calc(100vw - 71vw)
    }

  }


  /* ----------- iPhone 6+, 7+ and 8+ -----------*/
  @media only screen
  and (min-device-width: 414px)
  and (max-device-width: 736px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {

    .precipitation-column-style {
      width: calc(100vw - 67vw)
    }

  }

@primary-color: #3c61b4;