.map-settings-container .ant-typography {
  margin-bottom: 20px;
}

.map-settings-row {
  margin-left: 20px;
}

.map-settings-container span {
  font-size: 16px;
}

.map-modal-section {
  width: 100%;
}

.map-modal-section div {
  color: var(--default-text-color);
  background-color: var(--active-tab-text-color);
}

.ant-btn.active-tab {
  background-color: var(--active-tab-background-color);
  color: var(--active-tab-text-color);
}
