.ant-tag {
  color:rgba(0, 0, 0, 0.65);
}

.ant-radio-button-wrapper {
  margin-left: 5px;
}

.ant-radio-group {
  margin: 2;
  padding: 0;
  float: right;
}

.ant-checkbox-group {
  float: left;
  margin-top: 0.5vh;
}

#date-picker {
  width: '100%'
}

.schedule-date-picker {
  margin: 10px 0px;
  display: flex;
}

.checkbox  {
  overflow: auto;
  margin: 10px 0px;
  display: flex;
}

.padded-margin {
  margin: 10px 10px 0px 10px !important;
}

.schedule-chart-outer {
  flex: 1;
  min-height: 400px;
  overflow: hidden;
  margin-left: 10px;
  margin-right: 10px;
}

.schedule-chart-inner {
  position: relative;
  overflow-y: auto;
  height: 100%;
}

.schedule-chart-inner div:first-child {
  overflow: revert !important;
}

.no-data-chart-section {
  margin: 10px;
  height: calc(100vh - 300px) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d9d9d9;
}

.ant-page-header-heading-title {
  font-size: 18px;
  width: 100%;
}

.left-half {
  flex: 1;
  align-self: center;
}

.right-half {
  flex: 1;
}

.ant-picker-range-wrapper {
  position: absolute;
}

.ant-picker-dropdown-placement-bottomLeft .ant-picker-range-arrow {
  top: -5.3px;
}


/* ----------- iPhone 5, 5S, 5C and 5SE -----------

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-height: 568px)
  and (-webkit-min-device-pixel-ratio: 2) {

    .ant-page-header-heading-title {
      font-size: 14px;
    }

    .ant-picker-panel-container {
      overflow: scroll !important;
    }

    .ant-picker-range-wrapper {
      width: 440px;
      height: calc(200px - -40px) !important;
    }

    .schedule-date-picker {
      margin: 5px 0px;
    }
}

/* Portrait */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {

    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin-left: -2px;
    }

    .ant-radio-button-wrapper {
      font-size: 14px !important;
    }

    .no-data-chart-section {
      height: calc(100vh - 335px) !important;
    }

    .ant-picker-dropdown {
      left: 0px !important;
    }

    .ant-picker-dropdown-range {
      max-width: calc(100% - 20px) !important;
      overflow-x: scroll;
      margin-left: 10px;
    }

    #schedule-page-mobile {
      margin : 0px -4px;
      overflow: hidden;
    }

    .ant-page-header {
      margin-top: -7px;
    }
}

/* Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {

    .previous-date-button  {
      padding: 0px 8px;
    }

    .previous-date-icon {
      display: none;
    }

    .next-date-button {
      padding: 4px 4px;
    }

    .next-date-icon {
      display: none;
    }

    .ant-picker {
      width: '100%';
    }

}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2) {

    .ant-page-header-heading-title {
      font-size: 17px;
    }
}

/* Portrait */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {

    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin-left: 26px;
    }

    .ant-radio-button-wrapper {
      font-size: 16px !important;
    }

    .ant-picker-dropdown {
      left: 0px !important;
    }

    .ant-picker-dropdown-range {
      max-width: calc(100% - 20px) !important;
      overflow-x: scroll;
      margin-left: 10px;
    }

    .schedule-date-picker {
      margin: 5px 0px;
    }

    #schedule-page-mobile {
      margin : 0px -4px;
    }
}

/* Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {

}

/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 2) {

    .ant-page-header-heading-title {
      font-size: 18px;
    }
}

/* Portrait */
@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {

}

/* Landscape */
@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {

}

/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) {
    .anticon .anticon-arrow-right {
          margin-left: 0px;
    }

}

/* Portrait */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) {
    
    .schedule-chart-inner {
      width: 1000px;
    }
}

/* Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) {

} 

@media (min-width: 320px) and (max-width: 480px) and (orientation: landscape) {
  
  .previous-date-button  {
    padding: 0px 8px;
  }

  .previous-date-icon {
    display: none;
  }

  .next-date-button {
    padding: 4px 4px;
  }

  .next-date-icon {
    display: none;
  }

  .ant-picker {
    width: '100%';
  }
  
}

@media (min-width: 320px)  and (max-width: 682px) and (orientation: landscape) {

  .no-data-chart-section {
    height: 56vh;
  }

}

@media (min-width: 325px)  and (max-width: 669px) and (orientation: landscape) {

  .ant-radio-group {
    float: inherit;
    display: flex;
    justify-content: center;
  }

  .ant-checkbox-group {
    float: inherit;
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
    align-items: center;
  }

  .no-data-chart-section {
    height: 56vh;
  }

}

@media (max-height: 650px) and (max-width: 980px) and (orientation: landscape) {

  .no-data-chart-section {
    height: 56vh;
  }
}

/* ----------- Non-Retina Screens ----------- */
@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1) {

  .no-data-chart-section {
    margin: 10px;
    height: 65vh;
  }
}

/* ----------- Retina Screens ----------- */
@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (min-resolution: 192dpi) { 
  
}

/* ----------- Retina Screens ----------- */
@media screen 
  and (min-device-width: 1800px) 
  and (max-device-width: 2000px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (min-resolution: 192dpi)
  and (orientation: landscape) {
  }

@media (max-height: 1920px) and (min-width: 1600px) and (orientation: landscape) {

}

@media (min-width: 320px) and (max-width: 750px) {

  .ant-page-header-heading {
    font-size: 2vh;
  }

  .ant-radio-group {
    float: left;
  }

  .ant-checkbox-group-item {
    vertical-align: middle;
    align-items: center;
    display: contents;
  }

  .ant-checkbox-group {
    float: left;
    margin-left: 8px;
    margin-bottom: 8px;
  }

  .ant-checkbox + span {
    padding-right: 0px;
  }

  .ant-checkbox + span {
    padding-right: 0px;
  }

  .ant-checkbox + span .ant-tag {
    margin-right: 0px;
  }

  .checkbox  {
    display: block;
    overflow: hidden;
  }

  .left-half {
    float: inherit;
    display: flex;
    justify-content: space-between;
    margin-left: -5px;
  }
  
  .right-half {
    margin-top: 10px;
  }

  .ant-radio-group {
    float: inherit;
    display: flex;
    justify-content: space-between;
    margin-left: -5px;
  }

  .ant-checkbox-group {
    float: inherit;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    align-items: center;
    margin-left: 1px;
  }

  .ant-checkbox {
    align-self: center;
    margin-top: 3px;
    margin-left: 6px;
  }

  .ant-btn > .anticon + span, .ant-btn > span + .anticon {
     margin-left: 8px;
  }

  .header-button-text {
    display: none !important;

  }

  .back-schedule-text {
    display: none !important;
  }

  .previous-date-button  {
    padding: 0px 8px;
  }

  .previous-date-icon {
    display: none !important;
  }

  .next-date-button {
    padding: 4px 4px;
  }

  .next-date-icon {
    display: none !important;
  }

  .ant-picker-dropdown-range {
    max-width: 308px;
    overflow-x: scroll;
    margin-left: 10px;
  }

  .ant-picker-range-wrapper {
    width: 440px;
  }

  .ant-picker-range-arrow {
    margin-left: 120px;
    margin-right: 120px;
  }

  .schedule-chart-inner {
    min-width: 1000px;
    width: inherit;
  }

  .ant-radio-button-wrapper {
    padding: 0 6px;
    width: 100%;
    text-align: center;
  }

}

@media (min-width: 320px) and (max-width: 750px) {

  #container {
    height: 95vh;
    width: calc(100% - 0px);
  }

  .ant-page-header-heading {
    font-size: 2vh;
  }

  .ant-radio-group {
    float: left;
  }

  .ant-checkbox-group-item {
    vertical-align: middle;
    align-items: center;
    display: contents;
  }

  .ant-checkbox-group {
    float: left;
    margin-left: 8px;
    margin-bottom: 8px;
  }

  .ant-checkbox + span {
    padding-right: 0px;
  }

  .ant-checkbox + span {
    padding-right: 0px;
  }

  .ant-checkbox + span .ant-tag {
    margin-right: 0px;
  }

  .checkbox {
    display: block;
    overflow: hidden;
    margin-top: 2px;
  }

  .left-half {
    float: inherit;
    display: flex;
    justify-content: space-between;
    margin-left: -5px;
    margin-bottom: -3px;
  }
  
  .right-half {
    margin-top: 10px;
  }

  .ant-radio-group {
    float: inherit;
    display: flex;
    justify-content: space-between;
    margin-left: -5px;
  }

  .ant-checkbox-group {
    float: inherit;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    align-items: center;
    margin-left: 1px;
  }

  .ant-checkbox {
    align-self: center;
    margin-top: 3px;
    margin-left: 6px;
  }

  .schedule-chart-outer {
    width: calc(100% - 20px)!important;
    margin: -5px 22px 10px 10px;
    height: 45%;
    min-height: auto;
    flex: 1 1 auto;
    overflow-x: auto;
  }

  .schedule-chart-inner {
    min-width: 1000px;
    width: inherit;
  }

  .ant-radio-button-wrapper {
    padding: 0 6px;
    width: 100%;
    text-align: center;
  }

}

/*=========================================================================================================*/

  .dhx_cal_event_clear {
    height:32px;
    background: none !important;
  }

  .droplet-icon {
    display: flex;
    justify-content: center;
  }

  .dhx_month_link {
    display: none;
  }
  /* ----------- iPhone 5, 5S, 5C and 5SE -----------*/
  @media only screen 
    and (min-device-width: 320px) 
    and (max-device-width: 568px)
    and (-webkit-min-device-pixel-ratio: 2)
    and (orientation: portrait) {
  
     h1 {
        font-size: 20px;
        margin-left:2%
      }
  
  
    .sub-name {
      font-size: 13px;
    }
  
    .name {
      font-size: 13px;
    }
  
    .dhx_cal_container {
      font-size: 12px;
    }
  
    .dhx_matrix_cell {
      overflow-x: scroll !important;
    }
  
    .scheduler-outer {
      min-height: auto;
      flex: 1 1 auto;
      overflow-x: auto;
      overflow-y:hidden;
      margin-top: 4px !important;
      padding: 6px !important;
      margin-left: 0px !important;
      margin-right: 0px !important; 
      margin-bottom: 0px !important;
    }
  
    .dhx_cal_data {
      overflow-y: hidden;
      height : calc(100% - 81px) !important;
    }
  
    .right-half {
      margin-right: 6px;
    }
  
    .left-half {
      margin-top:3px;
    } 

    .dhx_cal_event_clear {
      height:32px;
      top: 141px;
    }
  
    .droplet-icon {
      display: flex;
      justify-content: center;
    }

    .dhx_cal_date {
      line-height: 15px;
      margin-left: 8px;
      color:black !important;
    }
    

    .dhx_month_head {
      text-align: center;
    }

    .scheduler-container-mobile {
      height:100%;
      width: auto;
    }

    .scheduler .ant-select-selector {
      font-weight: bold;
      padding-right: 0px !important;
    }

    .scheduler .ant-tag {
      border-radius: 10px;
      text-transform:uppercase;
      color:white;
    }

    .dhx_scale_bar:only-child {
      display:flex;
      font-size: 14px;
    }

    .dhx_scale_bar {
      color:black;
      display: flex;
      justify-content: center;
    }

    .dhx_scale_hour {
      color:black;
    }

    .dhx_month_head {
      color:black;
    }

    .dhx_after>.dhx_month_head {
      color:black;
    }
  
    .dhx_cal_tab {
      color:black;
    }

    .dhx_before>.dhx_month_head {
      color:black;
    }

    .dhx_now .dhx_month_head {
      border-radius: 50%;
      background: #1890ff;
      color: white;
      height: 21px;
      width: 21px;
      margin-left: 27%;
      display:flex;
      justify-content: center;
    } 
    
    .dhx_now .dhx_month_body {
      background-color: white !important;
      border-right: none;
    }
  
    .dhx_now {
      border-right: 1px solid #e0e0e0 !important;
    }

    .dhx_mini_calendar .dhx_now .dhx_month_head {
      padding-left: 0%;
      background: #1890ff;
      color: white;
      text-align: center;
      width: 31px !important;
      height: 31px !important;
      margin-left: 3px !important;
    }

    .dhx_mini_calendar .dhx_now {
      border-right: none !important;
    }

    .dhx_mini_calendar .dhx_now .dhx_month_body {
      background-color: none !important;

    }

    .dhx_cal_header {
      margin-top: -3px;
    }

    .dhx_month_head {
      padding: 0px;
    }
    
    body {
      position: fixed;
      overflow: hidden;
    }

    .ant-picker-range-wrapper {
      position: relative;
    }

    .ant-picker-range-arrow {
      bottom: -5.3px !important;
    }
  
  }
  
  
  /* ----------- iPhone 6, 6S, 7 and 8 -----------*/
  @media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px)  
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait){
  
    h1 {
      font-size: 18px;
      margin-right: 18%;
    }
  
    .button-group {
      margin-left:-4%;
    }
  
    .scheduler-outer {
      height: 100%;
      min-height: auto;
      flex: 1 1 auto;
      overflow-x: auto;
      margin-top: 1%;
      padding: 6px;
    }
  
  }
  
  
  /* ----------- iPhone 6+, 7+ and 8+ -----------*/
  @media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait){
  
    h1 {
      font-size: 18px;
      margin-right: 24%;
    }
  
    .scheduler-container {
      height:70vh;
    }
  
    .scheduler-outer {
      height: 100%;
      min-height: auto;
      flex: 1 1 auto;
      overflow-x: auto;
      overflow-y: auto;
      margin-top:1%;
      padding: 6px;
    }
  
  }
  
  /* ----------- iPhone X -----------*/
  @media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait){
  
      h1 {
        font-size: 18px;
        margin-right: 9%;
      }
  
      .scheduler-outer {
        height: 100%;
        min-height: auto;
        flex: 1 1 auto;
        overflow-x: auto;
        overflow-y: auto;
        margin-top:1%;
        padding: 6px;
      }
  
    }
  
  
  @media only screen and (max-width: 1200px){
    .scheduler-container {
      /* min-width: 1200px; */
      overflow-x: auto;
    }
  }
