:root {
  --bottom-position: calc(var(--header-height) + 15px + env(safe-area-inset-bottom) - env(safe-area-inset-top)) !important;
}

div.map-mobile {
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  justify-content: center;
}

div.map-mobile-container {
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
}

div.map-component-mobile {
  flex: 1;
}

div.controls-all-container {
  position: fixed;
  z-index: 501;
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;
  padding: 0 8px;
}

div.controls-box {
  margin-top: 10px;
  display: flex;
}

.controls-box .ant-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  border-radius: 5px;
  z-index: 501;
}

div.controls-left {
  display: flex;
  height: 45px;
  z-index: 501;
}

div.controls-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  right: 10px;
  z-index: 501;
}

.anomaly-detail-layer {
  z-index: 2;
  margin-right: 10px;
  margin-top: 5px;
}

div.bottomsheet-block {
  padding: 0 20px;
}

.anomaly-bottomsheet-block {
  padding: 0px;
}

.bottom-sheet-header {
  display: flex;
  justify-content: flex-end;
  padding-top: 8px;
}

.bottom-sheet-header .ant-btn {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

[data-rsbs-header] {
  padding-top: 8px !important;
}

[data-rsbs-header]:before {
  top: 8px !important;
}

[data-rsbs-overlay], [data-rsbs-backdrop], [data-rsbs-root]:after {
  z-index: 505 !important;
}

.bottomsheet-block .expand-btn {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 50px;
}

.text-imagery {
  display: flex;
  font-weight: bold;
  align-items: center;
  font-size: 20px;
  margin-top: -3px;
}

.layout-width {
  width: 100%;
}

.layout-height {
  background: none;
}

.mobile-header-color {
  background-color: #f0f2f5;
}

.map-view-select-wrapper {
  position: relative;
}

.map-view-select .anticon:not(.ant-select-suffix) {
  pointer-events: none;
}

span.map-view-select-icon {
  position: absolute;
  left: 8px;
  top: 11px;
  color: white;
  width: 20px;
  display: inline-block;
  height: 20px;
}

.map-view-select-icon svg {
  width: 20px;
  height: 20px;
  position: inherit;
  z-index: 1;
}

div.ant-select.map-view-select.ant-select-single .ant-select-selector,
div.ant-select.map-view-select.ant-select-borderless .ant-select-selector
{
  padding: 6px 10px 6px 45px;
  height: 40px ;
  background-color: rgba(0, 0, 0, 0.7) ;
  color: white;
  border: none;
  border-radius: 5px;
}

.layout-mobile div.ant-select.map-view-select {
  background-color: initial;
}

.desktop-controls-right-buttons {
  top: 101px;
}

div.map-mobile .leaflet-touch .leaflet-bar a {
  width: 38px !important;
  height: 40px !important;
  line-height: 40px !important;
}

.desktop-anomaly-drawer .ant-drawer-body {
  overflow-y: hidden;
}

.save-cancel-button-right {
  right: 285px !important;
}

.save-cancel-button-bottom {
  bottom: 10px !important;
}

.save-cancel-button-without-side-by-side-view {
  bottom: 265px !important;
}

.save-cancel-button-with-side-by-side-view {
  bottom: 235px !important ;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {

  .ant-picker-dropdown {
    position: relative;
    margin: 0px 8px;
  }

  .ant-picker-date-panel {
    width: 96vw !important;
  }

  .ant-picker-content {
    width: 95vw !important;
    margin: 0px -11px !important;
  }

  .map-mobile-anomaly-button {
    margin-left: 8px;
  }

  .tab-control-section {
    margin-top: -10px !important;
    margin-bottom: 7px !important;
    padding: 0px !important;
  }
}

.map-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column-gap: 10px;
  column-gap: 10px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px;
  margin-top: 10px;
  font-size: 16px;
}

.map-overlay .degree {
  position: relative;
  margin-left: 4px;
}

.map-overlay .degree::after {
  content: '';
  display: inline-block;
  height: 5px;
  width: 5px;
  border-radius: 10px;
  position: absolute;
  right: -6px;
  top: 3px;
  background-color: white;
}

.map-overlay .degree::before {
  display: none;
}

.bottom-sheet-header-container {
  display: flex;
  align-items: center;
}

.block-map-detail,
.ranch-map-detail {
  font-size: 18px;
  row-gap: 10px;
}

div.help-icon {
  margin-top: 5px;
  margin-left: 10px;
}

.satellite-imagery-container-highlight {
  border: 1px solid #000000;
}

.satellite-imagery-date-highlight {
  display: flex;
  justify-content: center;
  background-color: #000000;
  color: #FFFFFF;
  width: 100%;
}

.anomaly-icon {
  float: right;
  margin-top: 4px;
  margin-left: 7px;
}

.satellite-imagery-toggle-switch {
  display: flex;
  width: calc(100vw - 15vw);
  margin-top: 5px;
  margin-left: -2px;
  padding-top: 5px;
}

@media only screen and (max-height: 650px) {

  .map-settings-modal .ant-modal-body {
    overflow-y: auto !important;
    height: calc(100vh - 40vh) !important;
  }

}

.map-desktop-container {
  position: absolute;
  bottom: 4px;
  width: 500px;
  z-index: 501;
  margin-right: 400px;
  min-width: calc(100% - 400px);
  display: flex;
  height: 100%;
}

.map-desktop-control-box {
  width: 25% !important;
  margin-top: 0px !important;
}

.map-desktop-sensor-header {
  font-size: 20px;
  display: flex;
  align-items: center;
  position: absolute;
  left: 68px;
  top: 26px;
  margin-top: -4px;
}

.sensor-header-sub-text {
  margin-top: 24px;
}

.mobile-sensor-icon {
  margin-top: 8px;
}

.drawer-desktop .ant-drawer-header {
  padding-left: 20px;
}

.map-desktop-sensor-header-detail {
  display: flex;
  width: 100% !important;
  padding-left: 8px;
}

.data-type-popup-dropdown {
  padding: 5px !important;
  padding-left: 0px !important;
  border-radius: 10px !important;
}

.map-desktop-bottom-header-button {
  padding-top: 0px !important;
}

.map-desktop-header-title {
  margin-left: -41px;
}

.bottomsheet-block .map-valve-info {
  margin-top: -6px;
}

.bottom-sheet-header-container-sensor-details {
  padding: 0px 10px;
}

.map-header-icon {
  font-size: 20px;
  margin: 5px;
}

.map-header-text {
  font-size: 20px;
}

.map-header-flex-container {
  display: flex;
}

.cloud-coverage-icon-container {
  position: relative;
  bottom: 40px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  height: 0px;
}

.cloud-coverage-icon {
  color: var(--active-tab-text-color);
  stroke: var(--default-text-color);
  stroke-width: 40px;
}

.leaflet-draw-tooltip {
  background: #363636;
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid transparent;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  color: #fff;
  font: 12px/18px "Helvetica Neue", Arial, Helvetica, sans-serif;
  margin-left: 20px;
  margin-top: -21px;
  padding: 4px 8px;
  position: absolute;
  visibility: hidden;
  white-space: nowrap;
  z-index: 6;
}

.leaflet-draw-tooltip-subtext {
  color: #f8d5e4;
}

.leaflet-draw-tooltip:before {
  border-right: 6px solid black;
  border-right-color: rgba(0, 0, 0, 0.5);
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  content: "";
  position: absolute;
  top: 7px;
  left: -7px;
}

.leaflet-draw-tooltip-single {
  margin-top: -12px;
}

.form-padding {
  padding-top: 10px;
}

.form-margin {
  margin-bottom: 5px;
}

.form-button-margin {
  margin-top: -12px;
}

.edit-anomaly-form {
  width: 40%;
}

.map-header-tooltip {
  width: calc(100vw - 86vw);
}

.create-anomaly-button {
  display: none !important;
}

.form-button-width {
  width: 100%;
}

.form-button-right-align {
  margin-left: 5px;
}

div.map-desktop-container .ant-btn-primary.circle-create-btn {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 170px;
  font-weight: bolder;
  border-radius: 20px;
  height: 45px;
}

.desktop-controls-right {
  position: absolute;
  right: 10px;
  bottom: 50px;
}

div.map-desktop-container .circle-create-btn span {
  margin-left: 3px;
  margin-top: 5px;
  margin-right: -2px;
}

.controls-right-save-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 50%;
}

.controls-right-create-button {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 45%;
}

.desktop-anomaly-save-cancel-button {
  position: absolute;
  bottom: 10px;
  right: 400px;
}

.anomaly-drawing-cancel-button, .create-anomaly-cancel-button {
  background-color: var(--white-background) !important;
}

/* ----------- iPhone 5, 5S, 5C and 5SE -----------*/
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {

  .leaflet-draw-tooltip:before {
    display: none;
  }

  .leaflet-draw-tooltip {
    transform: translate(-13px, 150px) !important;
  }

  .edit-anomaly-form {
    width: calc(50vw + 128px);
  }

  .map-header {
    font-size: 20px;
    margin-top: 13px;
    font-weight: bold;
  }

  .map-header-tooltip {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(53vw + 20px);
    text-align: left;
  }

  .layout-mobile div.map-mobile .ant-btn-primary.circle-create-btn {
    bottom: var(--bottom-position);
    right: calc(15px + env(safe-area-inset-right));
    width: 165px;
    font-weight: bolder;
    border-radius: 20px;
    height: 46px;
  }

  .circle-create-btn span {
    margin-top: 3px;
    padding-left: -5px;
  }

  .controls-right-save-button {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    right: 10px;
    width: 46%;
  }

  .layout-mobile div.map-mobile .ant-btn-primary.anomaly-save-button,
  .layout-mobile div.map-mobile .ant-btn-ghost.create-anomaly-cancel-button {
    position: absolute;
    bottom: var(--bottom-position);
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .layout-mobile div.map-mobile .ant-btn-primary.anomaly-save-button {
    right: calc(15px + env(safe-area-inset-right));
  }

  .layout-mobile div.map-mobile .ant-btn-ghost.create-anomaly-cancel-button {
    right: calc(168px + env(safe-area-inset-right));
  }

  .anomaly-drawing-cancel-button, .create-anomaly-cancel-button {
    background-color: var(--white-background) !important;
  }

  .anomaly-bottom-sheet-height [data-rsbs-overlay] {
    height: calc(100vh - 50vh) !important;
  }

  div.bottomsheet-block {
    margin-bottom: 55px;
  }
}

/* ----------- iPhone 6, 7 and 8 ----------- */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {

  .edit-anomaly-form {
    width: calc(50vw + 44px);
  }

  .layout-mobile div.map-mobile .ant-btn-ghost.create-anomaly-cancel-button {
    right: calc(195px + env(safe-area-inset-right));
  }

  .anomaly-bottom-sheet-height [data-rsbs-overlay] {
    height: calc(100vh - 55vh) !important;
  }
}

/* ----------- iPhone 6+, 7+ and 8+ ----------- */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {

  .edit-anomaly-form {
    width: calc(50vw + 10px);
  }

  .layout-mobile div.map-mobile .ant-btn-ghost.create-anomaly-cancel-button {
    right: calc(218px + env(safe-area-inset-right));
  }

  .anomaly-bottom-sheet-height [data-rsbs-overlay] {
    height: calc(100vh - 50vh) !important;
  }
}

/* ----------- iPhone X -----------*/
@media only screen and (min-device-width: 375px) and (min-device-height: 812px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {

  .anomaly-bottom-sheet-height [data-rsbs-overlay] {
    height: calc(100vh - 47vh) !important;
  }

}

/* ----------- iPhone 13/14 -----------*/
@media only screen and (device-width: 390px) and (min-device-height: 664px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {

  .anomaly-bottom-sheet-height [data-rsbs-overlay] {
    height: calc(100vh - 51vh) !important;
  }

}

.parent-container {
  display: flex;
  flex-direction: row;
}

.left-child {
  width: 50%;
}

.right-child {
  width: 50%;
}

.map-layers-options-tooltip {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(53vw - 104px);
}

.map-overlay-option-align {
  margin-left: -17px;
}

.tab-control-section {
  margin-bottom: -10px;
  padding: 0px 10px;
}

[data-rsbs-footer] {
  padding-bottom: calc(105px + env(safe-area-inset-bottom)) !important;
}

.text-wrap {
  white-space: nowrap;
}

.map-anomaly-select-option .rc-virtual-list-scrollbar-thumb, 
.map-select-dropdown-settings .rc-virtual-list-scrollbar-thumb,
.satellite-data-type-list-options .rc-virtual-list-scrollbar-thumb {
  width: 0px !important;
}