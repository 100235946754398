@import "../../app/colors.less";

@header-icon-size: 25px;
@header-icon-color: white;

.layout-mobile .ant-drawer-content-wrapper {
  width: 80vw !important;
}

.mobile-side-menu {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.mobile-side-menu-item {
  display: flex;
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
}

.mobile-side-menu .mobile-side-menu__icon {
  display: flex;
  align-items: center;
  width: 44px;
  height: 50px;
}

.mobile-side-menu .mobile-side-menu__link {
  display: flex;
  align-items: center;
  flex-grow: 4;
  text-align: left;
  height: 50px;
  margin-bottom: 0px;
}

.trigger {
  font-size: @header-icon-size;
  color: @header-icon-color !important;
}

.layout-mobile .raf-icon-badge svg {
  fill: white;
}

.layout-mobile .raf-icon-badge:hover svg {
  fill: white;
}

.layout-mobile .notification-dropdown > div > div:nth-child(2) {
  right: 0px !important;
  top: 60px !important;
}

.layout-mobile .raf-dropdown-panel--arrow-right::after {
  right: 22px;
}

.layout-mobile .raf-dropdown-panel__content {
  max-height: calc(100vh - 170px - env(safe-area-inset-top) - env(safe-area-inset-bottom));
}

.layout-mobile .left-menu,
.layout-mobile .right-menu {
  flex: 1 1;
  display: block;
}

.ant-typography.fieldHandTitle {
  color: white;
  font-size: 22px;
  display: block;
  margin-top: 5px;
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
}

@primary-color: #3c61b4;