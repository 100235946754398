.map-sensor-detail {
    margin-top: 15px;

    .ant-typography.value-text {
        font-size: 44px;
    }

    .ant-typography.unit-text {
        font-size: 20px;
    }

    .ant-typography.info-text {
        font-size: 14px;
    }

    .soil-time-graph {
        height: 250px;
    }

    h3 {
        margin-top: 10px;
        margin-bottom: 0px;
        font-weight: bold;
    }
}

.bottomsheet-block {
    .sensor-detail-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .ant-card,
    .mobile-list-item {
        border: none;
        box-shadow: none;
        margin-bottom: 10px;
    }

    .ant-card-body,
    .mobile-list-item {
        padding: 0px !important;
        border: none;
    }

    .list-item-header,
    .title-and-alarm-container {
        display: none;
    }

    .ant-typography.value-text {
        font-size: 32px;
    }

    .ant-typography.unit-text {
        font-size: 20px;
    }

    .ant-typography.info-text {
        font-size: 14px;
    }

    .soil-moisture-chart-container {
        margin-top: 5px;
    }

    .value-text-med-crop-stress {
        font-size: 26px !important;
        margin-right: 15px;
    }

    .desktop-imagery-anomaly {
        .mobile-list-item {
            border: 1px solid #ccc !important;
            box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3) !important;
        }
    }
}

.bottomsheet-block.desktop-sidebar-details {
    .value-text-med-crop-stress{
        font-size: 26px !important;
    }

    .unit-med-crop-stress-margin {
        margin-left: 14px;
    }
}

.ranch-map-detail > .item-list > a  {
    border-bottom: 1px solid #ccc;
}

.anomaly-bottomsheet-block {
    .ant-card,
    .mobile-list-item {
        margin-bottom: 10px;
    }
}

.desktop-sidebar-details {
    padding: 0px !important;
    margin-top: -20px !important;
}

div.desktop-imagery-anomaly {
    margin: 0px -20px;
}

div.desktop-imagery-anomaly {
    .page-content {
        row-gap: 0px !important;
    }

    .recommendation-detail-actions {
        margin-top: -15px;
    }

    .ant-card-body {
        padding: 10px !important;
    }

    .error-report-button {
      margin-bottom: 50px;
    }
}

#map-drawer-details {
    padding-top: 0px;
}

.percent-available-water-graph-container {
    padding: 10px;
    border: 1px solid #ccc;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    overflow: hidden;
}

@media only screen and (max-width: 900px) {
    .percent-available-water-graph-container {
        margin-bottom: 70px;
    }
}

@primary-color: #3c61b4;