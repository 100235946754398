.recommendation-detail-container {
  width: 100%;
  overflow-y: auto;
}

.recommendation-detail-card1-container {
  display: flex;
  flex-direction: column;
  padding: 5px;
  font-size: 16px;
  align-items: baseline;
}

.recommendation-detail-card2 {
  display: flex;
  flex-direction: column;
  padding: 5px;
  font-size: 18px;
  align-items: baseline;
}

.recommendation-detail-card2-container {
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.recommendation-detail-card2-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 30px;
  width: 100%;
  margin-bottom: 10px;
}

.recommendation-detail-card2-deficit {
  display: flex;
  height: 100%;
  align-items: center;
  font-size: 16px;
}

.recommendation-detail-card2-vwc {
  display: flex;
  width: 50%;
}

.highcharts-axis-labels span { 
  word-break: break-all !important;
  width: 45px !important;
  white-space: normal !important;
}

.recommendation-comments-section {
  text-align: left;
  margin-left: -13px;
  margin-top: -12px;
}

.recommendation-comments-detail {
  display: flex;
  flex-direction: column;
  margin-top: -11px;
  margin-left: 8px;
}

.lan-long-align {
  font-size: 12px;
  margin-left: -11px;
}

.recommendation-add-comment-section, .ant-modal-body {
  padding-top: 5px !important;
}

.recommendation-add-comment-upload {
  border: none;
}

.error-report-button {
  margin-bottom: -15px;
  margin-top: -10px;
}

.recommendation-map-button { 
  background-color: rgba(0,0,0,0.7) !important;
  color: white;
  border: none;
}

.custom-button-margin { margin-top: 5px; }

@media (max-width: 900px) {
  .recommendation-detail-actions {
    padding-top: 12px;
  }
}
