@import "../../../app/colors.less";

.anomaly-comment {
  img {
    max-width: 100%;
  }
  .ant-comment-content-author-name {
    padding-right: 2px;
  }
}

.anomaly-comment-section {
  margin-bottom: -20px;
}

.anomaly-comment-form-actions {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

button.ant-btn.anomaly-comment-upload-button {
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 110px;
  color: #000;
  font-size: 17px;
}

.anomaly-comment-form .ant-form-item {
  margin-bottom: 10px;
}

.anomaly-comment-cancel {
  background: #E3E7E7;
}

.comment-action-buttons {
  .ant-btn {
    border: none;
    padding: 0;
    background: none;
    box-shadow: inherit;
    height: initial;
    width: initial;
    margin-right: 10px;
  }

  .edit {
    color: @primary
  }
}

@primary-color: #3c61b4;